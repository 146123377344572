<template>
  <div class="animated fadeIn">
    <b-card :title="$t('message.title.user')">
      <b-form @submit.prevent="search">
        <div class="row">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 my-1">
            {{ $t("message.userName") }} :
            <br />
            <b-form-input v-model="searchData.userName" />
          </div>
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-2 my-1">
            {{ $t("message.role") }} :
            <br />
            <b-form-select v-model="searchData.role" :options="role_select" />
          </div>
          <div class="col-sm-6 col-md-4 col-lg-4 col-xl-3 my-1">
            <br />
            <b-button class="col-md-5 my-1 ml-1 mr-1" variant="primary" :active="true" type="submit">
              <i class="fa fa-search"></i>&nbsp;{{ $t("message.search") }}
            </b-button>

            <b-btn class="col-md-5 my-1 ml-1 mr-1"  variant="success" v-on:click="createUser()">
              <i class="fa fa-plus-square"></i>&nbsp;{{ $t("message.create") }}
            </b-btn>
          </div>
        </div>
      </b-form>
      <br />
      <div class="table-responsive">
        <table class="table-striped table2">
          <thead class="bg-primary" style="text-align: center">
            <th style="text-align: right">USER ID</th>
            <th>{{ $t("message.userName") }}</th>
            <th>{{ $t("message.role") }}</th>
            <th>credit</th>
            <th>sms credit</th>
            <th>{{ $t("message.createdAt") }}</th>
            <th>{{ $t("message.updatedAt") }}</th>
            <th>Action</th>
          </thead>
          <tbody>
            <tr v-for="items in rowData" :key="items.userId">
              <td style="text-align: right">{{ items.userId }}</td>
              <td>{{ items.userName }}</td>
              <td style="text-align: center">{{ items.role }}</td>
              <td style="text-align: right">{{ Number(items.credit ? items.credit.value : "").toLocaleString() }} </td>
              <td style="text-align: right">{{ Number( items.credit ? items.credit.valueSMS : "").toLocaleString() }}</td>
              <td style="text-align: center"><label style="width: 170px;">{{ items.createdAt | moment("YYYY-MM-DD HH:mm") }}</label></td>
              <td style="text-align: center"><label style="width: 170px;">{{ items.updatedAt | moment("YYYY-MM-DD HH:mm") }}</label></td>
              <td style="text-align: center">
                <b-btn class="ml-1 mr-1 my-1" style="width: 70px;" size="sm" variant="warning" @click="edit(items)">{{ $t("message.edit") }}</b-btn>
                <b-btn class="ml-1 mr-1 " style="width: 70px;" size="sm" variant="success" @click="showModalAddcredit(items)">{{ $t("message.addCredit") }}</b-btn>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <b-pagination size="md" :total-rows="totalRows" v-model="pagination" :per-page="itemperPage" @input="pageChanged(pagination)"></b-pagination>
    </b-card>

    <b-modal ref="changePwd" :title="$t('message.changePassword')" @ok="changePwd">
      <table class="table3">
        <tr>
          <td>{{ $t("message.oldPassword") }}</td>
          <td>
            <b-form-input class="form-control" type="password" v-model="ModalNewPwd.oldPassword" required></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.newPassword") }}</td>
          <td>
            <b-form-input class="form-control" type="password" v-model="newPassWord" required></b-form-input>
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.confirmPassword") }}</td>
          <td>
            <b-form-input class="form-control" type="password" v-model="confirmPassWord" required></b-form-input>
          </td>
        </tr>
      </table>
    </b-modal>

    <b-modal ref="addCreditModal" :title="$t('message.addCredit')" @ok="addCredit">
      <table class="table3">
        <tr>
          <td>{{ $t("message.userName") }}</td>
          <td>{{ modalAddcredit.userName }}</td>
        </tr>
        <tr>
          <td>{{ $t("message.type") }}</td>
          <td>
            <b-form-select v-model="addCreditType" :options="addCreditSelector" />
          </td>
        </tr>
        <tr>
          <td>{{ $t("message.addOrDeductCredit") }}</td>
          <td>
            <b-form-select v-model="modalAddcredit.creditType" :options="creditType" />
          </td>
        </tr>
        <tr v-if="addCreditType === 'credit'">
          <td>{{ $t("message.value") }}</td>
          <td>
            <b-form-input class="form-control" type="number" v-model.number="modalAddcredit.value" min="0" required></b-form-input>
          </td>
        </tr>
        <tr v-if="addCreditType === 'sms'">
          <td>{{ $t("message.value") }}</td>
          <td>
            <b-form-input class="form-control" type="number" min="0" v-model.number="modalAddcredit.valueSMS" required></b-form-input>
          </td>
        </tr>
      </table>
    </b-modal>
  </div>
</template>

<script>
import cSwitch from '../../components/Switch'
import webServices from '../../script'
import moment from "moment";
export default {
  name: 'users',
  components: {
    cSwitch
  },
  computed: {
    CheckPwd() {
      return 'false'
    }
  },
  data() {
    return {
      searchData: {},
      role_select: [
        { value: null, text: 'All' },
        { value: 'admin', text: 'admin' },
        { value: 'dealer', text: 'dealer' },
        { value: 'user', text: 'user' }
      ],
      addCreditSelector: [
        { value: 'sms', text: 'sms' },
        { value: 'credit', text: 'credit' }
      ],
      creditType: [
        { value: 'add', text: 'เพิ่ม' },
        { value: 'deduct', text: 'หัก' }
      ],
      status_select: [
        { value: null, text: 'All' },
        { value: true, text: 'ON' },
        { value: false, text: 'OFF' }
      ],
      userType: [
        { value: null, text: 'Select type' },
        { value: 'admin', text: 'admin' },
        { value: 'wonderwash', text: 'wonderwash' },
        { value: 'oem', text: 'oem' },
        { value: 'callcenter', text: 'callcenter' },
        { value: 'other', text: 'อื่นๆ' }
      ],
      titlename: [
        { value: null, text: 'Select title' },
        { value: 'mr', text: 'Mr. นาย' },
        { value: 'ms', text: 'Miss. นางสาว' },
        { value: 'mrs', text: 'Mrs. นาง' }
      ],
      address: {},
      rowData: [],
      pagination: 1,
      totalRows: 1,
      itemperPage: 30,
      newPassWord: '',
      confirmPassWord: '',
      ModalNewPwd: {
        userId: 0,
        userName: ''
      },
      modalAddcredit: {
        userId: 0,
        userName: '',
        creditType: ''
      },
      detail: {},
      addCreditType: ''
    }
  },
  methods: {
    getUserList(params) {
      this.$Progress.start()
      webServices
        .getUserList(params)
        .then(res => {
          // console.log(res.data)
          this.$Progress.finish()
          this.rowData = res.data
        })
        .catch(err => {
          this.$Progress.fail()
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
          console.log(err)
          console.log('error @user')
          this.$toast.error({
            title: 'ERROR',
            message: 'เกิดข้อผิดพลาด'
          })
          if (err.response.status === 401) {
            webServices.tokenExpire()
          }
        })
    },
    createUser() {
      console.log('createUser')
      this.$router.push('/user/create')
    },
    search() {
      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName_ = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest
      
      this.getUserList(this.searchData)
    },
    showModalPwd(user) {
      this.ModalNewPwd.userId = user.userId
      this.ModalNewPwd.userName = user.userName
      this.$refs.changePwd.show()
    },
    showModalAddcredit(user) {
      this.modalAddcredit.userId = user.userId
      this.modalAddcredit.userName = user.userName
      this.$refs.addCreditModal.show()
    },
    edit(user) {
      this.$router.push({ path: `/user/edit/${user.userId}` })
    },
    addCredit() {
      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.modalAddcredit.userName = localStorageUser.userName
      this.modalAddcredit.userRole = localStorageUser.role
      this.modalAddcredit.userType = localStorageUser.type
      this.modalAddcredit.userConfiglanguage = localStorageUser.config.language
      this.modalAddcredit.datetimeRequest = datetimeRequest

      if (this.addCreditType === 'sms') {
        if (this.modalAddcredit.creditType === 'deduct') {
          this.modalAddcredit.valueSMS = this.modalAddcredit.valueSMS * -1
        }
        webServices.addCreditSMS(this.modalAddcredit).then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'สำเร็จ'
            })
            this.addCreditType = ''
            this.modalAddcredit = {
              userId: 0,
              userName: ''
            }
            this.getUserList()
          } else {
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
        })
      } else if (this.addCreditType === 'credit') {
        if (this.modalAddcredit.creditType === 'deduct') {
          this.modalAddcredit.value = this.modalAddcredit.value * -1
        }
        webServices.addCredit(this.modalAddcredit).then(res => {
          if (res.data.success) {
            this.$toast.success({
              title: 'SUCCESS',
              message: 'สำเร็จ'
            })
            this.addCreditType = ''
            this.modalAddcredit = {
              userId: 0,
              userName: ''
            }
            this.getUserList()
          } else {
            const error = webServices.showError(res.data)
            this.$toast.error({
              title: 'ERROR',
              message: JSON.stringify(error)
            })
          }
        })
      }
    },
    editUserInfo() {
      webServices.editUserInfo(this.detail).then(res => {
        if (res.data.success) {
          this.$toast.success({
            title: 'SUCCESS',
            message: 'สำเร็จ'
          })
        } else {
          const error = webServices.showError(res.data)
          this.$toast.error({
            title: 'ERROR',
            message: JSON.stringify(error)
          })
        }
        this.getUserList(this.searchData)
      })
    },
    changePwd() {
      if (this.newPassWord === this.confirmPassWord && this.newPassWord && this.confirmPassWord && this.ModalNewPwd.oldPassword) {
        this.ModalNewPwd.passWord = this.newPassWord
        // console.log(this.ModalNewPwd)
        webServices
          .changePwd(this.ModalNewPwd)
          .then(res => {
            // console.log(res)
            if (res.data.success) {
              this.clearPassWord()
              this.$toast.success({
                title: 'SUCCESS',
                message: 'เปลี่ยนรหัสผ่านสำเร็จ'
              })
            } else {
              const error = webServices.showError(res.data)
              this.$toast.error({
                title: 'ERROR',
                message: JSON.stringify(error)
              })
            }
            this.ModalNewPwd = {}
            this.getUserList()
          })
          .catch(err => {
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
            console.log('error @change password')
            console.log(err)
            this.$toast.error({
              title: 'ERROR',
              message: 'เกิดข้อผิดพลาด'
            })
            if (err.response.status === 401) {
              webServices.tokenExpire()
            }
          })
      } else {
        this.$toast.error({
          title: 'ERROR',
          message: 'กรุณากรอกข้อมูลให้ถูกต้อง'
        })
      }
    },
    clearPassWord() {
      this.newPassWord = ''
      this.confirmPassWord = ''
      this.ModalNewPwd = {}
    }
  },
  mounted() {
    var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
    var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

    this.searchData.userName_ = localStorageUser.userName
    this.searchData.userRole = localStorageUser.role
    this.searchData.userType = localStorageUser.type
    this.searchData.userConfiglanguage = localStorageUser.config.language
    this.searchData.datetimeRequest = datetimeRequest

    this.getUserList(this.searchData)
  }
}
</script>

<style lang="scss">
.table3 {
  width: 100%;
  border: none;
}
.table3 td,
.table3 th {
  padding: 8px;
  vertical-align: middle;
  text-align: center;
}
</style>